/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

@font-face {
  font-family: "CeraProLight";
  src: url("../../@core/layouts/components/menu/vertical-menu/fonts/CeraProLight.woff") format("woff");
}

* {
  font-family: 'CeraProLight', sans-serif;
}

.navigation {
  display: flex;
  flex-direction: column;
  gap: 15px;

  .nav-item,
  .nav-item.active {
    a {
      font-size: 15px !important;
      display: flex !important;
      color: #5E5873 !important;
      font-style: normal !important;
      align-items: center !important;
      text-align: center !important;
      font-family: 'Montserrat', serif !important;
      line-height: 24px !important;
      background: none !important;
      box-shadow: none !important;
    }
  }

  .collapse.show>.active>a {
    color: #fff !important
  }
}

.logo {
  margin-right: 30%;
}

.page-title {
  font-family: 'CeraProLight', sans-serif;
  color: #000000;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
}

.reactstrap-input:not([type="checkbox"]) {
  border-radius: 14px !important;
  border: 1.5px solid #DFDFDF;
}

.reactstrap-input ::placeholder {
  font-family: 'Roobert';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #626262;

}

.card {
  border-radius: 16px !important;
}

.btn-dark {
  border-color: #000000 !important;
  background-color: #000000 !important;
}

[dir] .form-check-dark .form-check-input:checked {
  border-color: #000000 !important;
  background-color: #000000 !important;

}

.modal-header {
  border-bottom: #ffffff !important;
  background-color: #ffffff !important;

  border-radius: 14px !important;
}

.modal-title {
  font-family: 'CeraProLight', sans-serif;
  color: #000000;
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 19px;
}

[dir] .modal-content {
  border-radius: 14px !important;
}

.modal-body {
  margin-right: 36px;
  margin-left: 36px;
}

.mail-compass-body {
  padding: 36px !important;
}

.in-circle {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5.56818px;
  gap: 5.57px;

  width: 35px;
  height: 35px;

  /* Neutral/50 */

  background: #F3F3F3;
  /* Neutral/400 */

  border: 1.67045px solid #555555;
  border-radius: 30.0682px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;

  cursor: pointer;
  margin-left: 5px;
}

.in-circle-danger {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5.56818px;
  gap: 5.57px;

  width: 35px;
  height: 35px;

  background: #F0E0E0;
  /* Error/600 */

  border: 1.67045px solid #D92D20;
  border-radius: 25.6136px;

  /* Inside auto layout */

  flex: none;
  order: 2;
  flex-grow: 0;

  cursor: pointer;
  margin-left: 5px;
}

.checkbox-input {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 6px 37px;
  gap: 10px;
  width: 100px;
  height: 23px;
  border-radius: 15px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.checkbox-input.active {
  background: #D9F2D8;
}

.checkbox-input.active * {
  color: #185200;
}

.checkbox-input.disactive {
  background: #ffa4a4;
}

.checkbox-input.disactive * {
  color: #6b0000;
}

.checkbox-input * {
  font-family: 'CeraProLight', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;

}

.material-select {
  background: #F9FAFB;
  border: 1px solid #D1D5DB;
  border-radius: 8px;

}

.MuiSvgIcon-root {
  width: 18px;
  height: 18px;

}

.btn {
  border-radius: 8px !important;
}

[dir] .page-item.active .page-link {
  background-color: #000000 !important;
}

.form-check-input:not([type="checkbox"]) {
  border-radius: 0px !important;
}

.form-check-input:checked {
  background-color: #000000 !important;
  border-color: #000000 !important;
}

.form-check-input:not(:disabled):checked {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0);
}

[dir] .main-menu.menu-light .navigation>li ul .active {
  background: #000000;
  box-shadow: 0 0 10px 1px rgb(0 0 0 / 70%);
}

[dir] .main-menu.menu-light .navigation>li ul .active a span {
  color: #ffffff;
}

audio::-webkit-media-controls-play-button,
audio::-webkit-media-controls-panel {
  background-color: #fff;
  color: #fff;

}

.css-1s2u09g-control {
  border-radius: 15px !important;
}

.select-currency {
  float: right;
  margin-right: 30;
  width: 220px;
}

.select__menu .select__menu-list .select__option,
[dir] .select__menu .select__menu-list .react-select__option,
[dir] .select__menu .react-select__menu-list .select__option,
[dir] .select__menu .react-select__menu-list .react-select__option,
[dir] .react-select__menu .select__menu-list .select__option,
[dir] .react-select__menu .select__menu-list .react-select__option,
[dir] .react-select__menu .react-select__menu-list .select__option,
[dir] .react-select__menu .react-select__menu-list .react-select__option {
  border-radius: 50px !important;
}

.select__menu .select__menu-list .select__option.select__option--is-focused,
[dir] .select__menu .select__menu-list .react-select__option.select__option--is-focused,
[dir] .select__menu .react-select__menu-list .select__option.select__option--is-focused,
[dir] .select__menu .react-select__menu-list .react-select__option.select__option--is-focused,
[dir] .react-select__menu .select__menu-list .select__option.select__option--is-focused,
[dir] .react-select__menu .select__menu-list .react-select__option.select__option--is-focused,
[dir] .react-select__menu .react-select__menu-list .select__option.select__option--is-focused,
[dir] .react-select__menu .react-select__menu-list .react-select__option.select__option--is-focused {
  background-color: #000000 !important;
}

[dir] .select__menu .select__menu-list .select__option.select__option--is-selected,
[dir] .select__menu .select__menu-list .react-select__option.select__option--is-selected,
[dir] .select__menu .react-select__menu-list .select__option.select__option--is-selected,
[dir] .select__menu .react-select__menu-list .react-select__option.select__option--is-selected,
[dir] .react-select__menu .select__menu-list .select__option.select__option--is-selected,
[dir] .react-select__menu .select__menu-list .react-select__option.select__option--is-selected,
[dir] .react-select__menu .react-select__menu-list .select__option.select__option--is-selected,
[dir] .react-select__menu .react-select__menu-list .react-select__option.select__option--is-selected {
  background-color: #000000 !important;
}

.select__menu .select__menu-list .select__option.select__option--is-focused,
.select__menu .select__menu-list .react-select__option.select__option--is-focused,
.select__menu .react-select__menu-list .select__option.select__option--is-focused,
.select__menu .react-select__menu-list .react-select__option.select__option--is-focused,
.react-select__menu .select__menu-list .select__option.select__option--is-focused,
.react-select__menu .select__menu-list .react-select__option.select__option--is-focused,
.react-select__menu .react-select__menu-list .select__option.select__option--is-focused,
.react-select__menu .react-select__menu-list .react-select__option.select__option--is-focused {
  color: #ffffff !important;
}

.select__control.select__control--is-focused,
[dir] .select__control.react-select__control--is-focused,
[dir] .react-select__control.select__control--is-focused,
[dir] .react-select__control.react-select__control--is-focused {
  border-color: #000000 !important;
  /*height: 55px;*/
  border-radius: 15px !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.ace_editor,
.ace_editor * {
  font-family: "Monaco", "Menlo", "Ubuntu Mono", "Droid Sans Mono", "Consolas", monospace !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  letter-spacing: 0 !important;
}

.no-border-radius {
  border-radius: 0px !important;
}

.fallback-spinner.app-loader {
  height: 100%;
  max-height: 100vh;
}